import { useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';
import { navigate } from '@ecp/utils/routing';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { AgreementContent, Button, Form } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import {
  DateOfBirthQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  PhoneNumberQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  makeSurePrefillFlowInStore,
  updatePageStatus,
  useForm,
  useGetPersonFields,
  useIsPrimaryInsuredPersonLock,
  usePrimaryInsuredPersonRef,
  useUpdatePNIInsuredType,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './PersonPage.styles';

interface SubmitParams {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  validateForm: () => ValidateFormResult;
  updatePNIInsuredType: () => void;
}

const doSubmit =
  ({
    onNext,
    patchFormValues,
    setIsSubmitting,
    validateForm,
    updatePNIInsuredType,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async () => {
    setIsSubmitting(true);
    updatePNIInsuredType();

    if (validateForm().isValid) {
      // Patch form values on Continue button click
      // This would be helpful for fields with defaults
      await patchFormValues();
      await onNext();
    }

    setIsSubmitting(false);
  };

// TODO This is essentially replica of libs/features/sales/shell/src/forms/PersonForm/PersonForm.tsx
// and libs/features/sales/shell/src/formBody/PersonForm/PersonFormQuestions.tsx
// Look into some shareability/reusability
export const PersonForm: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    person: { middleName, suffix },
  } = useGetPersonFields();
  const getInitValues = useGetInitValues();
  const getConditions = useGetConditionValues();
  const getFields = useGetFields();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });
  const pniRef = usePrimaryInsuredPersonRef();
  const isPniLocked = useIsPrimaryInsuredPersonLock();
  const isDisabled = isSubmitting || isPniLocked;
  const navigateToNextPage = useNavigateToNextPage();
  const updatePNIInsuredType = useUpdatePNIInsuredType();

  const onNext = useEvent(async () => {
    await dispatch(makeSurePrefillFlowInStore());
    dispatch(updatePageStatus(NavStatus.VALID, PagePath.RENTERS_DISCOUNTS));
    await navigateToNextPage();
  });

  const navigateToPrivacyPolicy = useEvent((): void => {
    trackClick({ action: 'BodyTextPrivacyPolicyLink', label: 'PrivacyPolicy' });
    navigate(PagePath.PRIVACY_POLICY, { external: true });
  });

  const navigateToParticipatingCarriers = useEvent(() => {
    trackClick({ action: 'ParticipatingInsurersLink', label: 'ParticipatingInsurers' });
    navigate(PagePath.PARTICIPATING_INSURERS, { external: true });
  });

  const handleSubmit = useEvent(async () => {
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        setIsSubmitting,
        validateForm,
        updatePNIInsuredType,
      }),
    );
  });

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem xs={12}>
            <p className={classes.subHeader}>What's your name?</p>
          </GridItem>
          <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
            <FirstNameQuestion personRef={pniRef} disabled={isDisabled} />
          </GridItem>
          {middleName?.exists && (
            <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
              <MiddleNameQuestion personRef={pniRef} disabled={isDisabled} />
            </GridItem>
          )}
          <GridItem
            topSpacing='sm'
            xs={12}
            md={6}
            className={middleName?.exists ? classes.columnLeft : classes.columnRight}
          >
            <LastNameQuestion personRef={pniRef} disabled={isDisabled} />
          </GridItem>
          {suffix?.exists && (
            <GridItem
              topSpacing='sm'
              xs={12}
              md={6}
              className={middleName?.exists ? classes.columnRight : classes.columnLeft}
            >
              <SuffixQuestion personRef={pniRef} disabled={isDisabled} />
            </GridItem>
          )}

          <GridItem topSpacing='lg' xs={12}>
            <p className={classes.subHeader}>What's your contact info?</p>
          </GridItem>
          <GridItem topSpacing='sm' md={6} xs={12} className={classes.columnLeft}>
            <EmailQuestion personRef={pniRef} disabled={isDisabled} />
          </GridItem>
          <GridItem topSpacing='sm' md={6} xs={12} className={classes.columnRight}>
            <PhoneNumberQuestion
              personRef={pniRef}
              disabled={isDisabled}
              label='Phone (optional)'
            />
          </GridItem>

          <GridItem topSpacing='lg' xs={12}>
            <p className={classes.subHeader}>When were you born?</p>
          </GridItem>
          <GridItem topSpacing='sm' md={6} xs={12} className={classes.columnLeft}>
            <DateOfBirthQuestion personRef={pniRef} disabled={isDisabled} />
          </GridItem>
          <GridItem topSpacing='lg' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='ContinueButton'
              trackingName='ContinueButton'
              trackingLabel='Continue'
              analyticsElement='choice.personPage.continueButton'
              type='submit'
            >
              Continue
            </Button>
          </GridItem>

          <GridItem topSpacing='sm' xs={12}>
            <AgreementContent
              onNavigateToPrivacyPolicy={navigateToPrivacyPolicy}
              onNavigateToParticipatingCarriers={navigateToParticipatingCarriers}
            />
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
