import { useEffect } from 'react';

import { LogoSpinnerWrapper } from '@ecp/components';
import { GlobalLoading, Page } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getBridgeOver, updatePageStatus } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { QuotesError, useQuotesPage } from '@ecp/features/sales/shell';

import metadata from './metadata';
import { useStyles } from './QuotesPage.styles';

// TODO See 'apps/sales/bundle/src/views/Quotes/QuotesPage.tsx' for QuotesHO2PromptGuard component implementation
// which is necessary in the Home Owners flow.
export const QuotesPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    doOffersForSelectedLobExist,
    dalSessionId,
    inquiryId,
    loading,
    xRequestId,
    transactionId,
  } = useQuotesPage();
  const navigateToNext = useNavigateToNextPage();
  const isBridgeOver = useSelector(getBridgeOver);
  useEffect(() => {
    if (!loading && doOffersForSelectedLobExist) {
      dispatch(updatePageStatus(NavStatus.VALID));
      navigateToNext(true);
    }
  }, [dispatch, doOffersForSelectedLobExist, loading, navigateToNext]);

  if (!loading && !doOffersForSelectedLobExist)
    return (
      <div className={classes.root}>
        <Page analyticsElement='choice.quoteSummaryPage.page'>
          <div className={classes.errorContainer}>
            <QuotesError
              dalSessionId={dalSessionId}
              inquiryId={inquiryId}
              xRequestId={xRequestId}
              transactionId={transactionId}
            />
          </div>
        </Page>
      </div>
    );

  // Show LogoSpinner instead of Global laoding for Zillow bridge over
  if (metadata.hideGlobalLoadingOnBridgeOver && isBridgeOver) {
    return <LogoSpinnerWrapper />;
  }

  return (
    <GlobalLoading loadingPageTitle="We're building your quote" data-testid='viewYourQuotes' />
  );
};
