import type { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { Alert } from '@ecp/components';
import { useIsMobile } from '@ecp/themes/base';

import { HomePrefillSummaryPageLink } from './HomePrefillSummaryPageLink';
import { useStyles } from './ReviewHomePrefillAlert.styles';

const Text: FC = () => {
  return (
    <Typography>
      To save you time, we use data collected from multiple sources to estimate your home's
      replacement cost and calculate your premium.
    </Typography>
  );
};

const DesktopLayout: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.desktopLayoutGridContainer}>
      <Text />
      <HomePrefillSummaryPageLink />
    </div>
  );
};

const MobileLayout: FC = () => {
  return (
    <Stack direction='column' spacing={1} alignItems='flex-start'>
      <Text />
      <HomePrefillSummaryPageLink />
    </Stack>
  );
};

interface Props {
  className?: string;
}

export const ReviewHomePrefillAlert: FC<Props> = (props) => {
  const { className } = props;
  const isMobile = useIsMobile();

  return (
    <Alert type='info' withIcon className={className}>
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </Alert>
  );
};
