import { memo, useCallback } from 'react';

import { Divider, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray } from '@ecp/utils/common';

import { GridItem, HelpBox, Link } from '@ecp/components';
import { env } from '@ecp/env';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getAreSomeSelectedProductsIndicative,
  getIsBundleForOfferProductsSelected,
  getOfferProductsSelectedByType,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
  isProductHome,
  isProductRenters,
} from '@ecp/features/shared/product';
import { GraphicUIAgentAvatarImageUrl, IconCardHome, IconCardRent } from '@ecp/themes/base';

import { useIsCustomizeCoveragesEnabled } from '../../util';
import { CoveragesAccordion } from '../CoveragesAccordion';
import { useStyles } from '../CoveragesForm.styles';
import type { CoveragesSummaryProps } from '../CoveragesSummary';
import { CoveragesSummary } from '../CoveragesSummary';
import coveragesMetadata from '../metadata';
import { PolicyStartDateCalendar } from '../PolicyStartDateCalendarForm';
import { ReviewHomePrefillAlert } from '../ReviewHomePrefillAlert/ReviewHomePrefillAlert';
import { useShouldShowReviewHomePrefillAlert } from '../ReviewHomePrefillAlert/useShouldShowReviewHomePrefillAlert';
import { useShowLiftoffMobilePolicyStartDateOnAutoOrPropertyForms } from '../util/topLayout';
import metadata from './metadata';
import type { PropertyCoveragesFormProps } from './types';
import { usePropertyCoveragesForm } from './util';

type DisclaimerProps = Pick<PropertyCoveragesFormProps, 'messages' | 'setCarrierMessagingOpen'> & {
  stateCode: string;
};

export const HomeRatingCriteriaDisclaimer: React.FC<DisclaimerProps> = memo(
  ({ messages = emptyArray }) => {
    const { classes } = useStyles();
    const navigateToRatingCriteriaPage = useNavigateToPage(PagePath.RATING_CRITERIA);

    const navigateToRatingCriteria = useCallback(async () => {
      await navigateToRatingCriteriaPage();
      trackClick({ action: 'rating_criteria_link', label: 'rating_criteria' });
    }, [navigateToRatingCriteriaPage]);

    return (
      <div>
        <p>
          We use information from a combination of data sources to calculate your premium and
          estimate your dwelling's replacement cost (Coverage A).{' '}
          <Link component='button' onClick={navigateToRatingCriteria}>
            Here's some of the information we used.
          </Link>
        </p>
        {/* If we don't have any carrier messages we don't want to display the divider because it's unnecessary */}
        {messages.length > 0 && <Divider className={classes.disclaimerDivider} />}
      </div>
    );
  },
);

const MessageDisclaimer: React.FC<DisclaimerProps> = memo(
  ({ setCarrierMessagingOpen, stateCode, messages = emptyArray }) => {
    const messageNames = messages.map((element) => element.name || '');

    const openCarrierMessaging = useCallback(
      () => setCarrierMessagingOpen(true),
      [setCarrierMessagingOpen],
    );

    return (
      <div>
        {messageNames.length > 0 && (
          <p>
            Please review the following important information for {stateCode}.&nbsp;
            <Link component='button' onClick={openCarrierMessaging} display='inline'>
              {messageNames.join(', ')}
            </Link>
          </p>
        )}
      </div>
    );
  },
);

export const PropertyCoveragesForm: React.FC<PropertyCoveragesFormProps> = memo((props) => {
  const { propertyDiscountItems } = props;
  const { classes, cx } = useStyles();
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const { propertyCoverages } = usePropertyCoveragesForm(props);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const areSomeSelectedProductsIndicative = useSelector(getAreSomeSelectedProductsIndicative);

  const isCustomizeCoveragesEnabled = useIsCustomizeCoveragesEnabled?.();
  const { showPolicyStartDate } = useShowLiftoffMobilePolicyStartDateOnAutoOrPropertyForms();
  const { topOfPropertyCoveragesForm } = useShouldShowReviewHomePrefillAlert();

  if (!propertyCoverages || !propertyOfferProduct) return null;

  const productDisplayName = getProductDisplayNameFromProduct(propertyOfferProduct);
  const productName = getProductNameFromProduct(propertyOfferProduct);
  const coverageGroupKeys = Object.keys(metadata[propertyOfferProduct].titles);

  const renderCoveragesSummary = (): React.ReactElement => {
    const propertyCoverageItems: NonNullable<CoveragesSummaryProps['coverageGroups']> =
      coverageGroupKeys.map((key) => ({
        title: metadata[propertyOfferProduct].titles[key],
        items: propertyCoverages[key],
      }));

    return (
      <>
        <CoveragesSummary
          discountItems={propertyDiscountItems?.[propertyOfferProduct]}
          path={`/quote/${getProductNameFromProduct(propertyOfferProduct)}/discounts`}
          product={productName}
          summaryType='discounts'
          title={`${productDisplayName} discounts applied`}
          trackingName={`Edit${productDisplayName}Discounts`}
        />
        <CoveragesSummary
          coverageGroups={propertyCoverageItems}
          path={PagePath.QUOTES}
          summaryType='coverages'
          title={`${productDisplayName} Coverages`}
          trackingName={`Edit${productDisplayName}Coverages`}
        />
      </>
    );
  };

  const renderCoverages = (): React.ReactElement => {
    const discountText = !env.static.isAgent ? 'Policy discounts applied' : 'Discounts applied';

    return (
      <>
        {isBundle && (
          <Grid
            container
            item
            xs={12}
            className={cx(
              classes.addSpacing,
              classes.productHeading,
              showPolicyStartDate && classes.productHeadingWhenShowingPolicyStartDate,
            )}
          >
            {!showPolicyStartDate &&
              (isProductHome(propertyOfferProduct) && env.static.isAgent ? (
                <IconCardHome className={classes.productIcon} />
              ) : (
                <IconCardRent className={classes.productIcon} />
              ))}
            <h2>{`${productDisplayName} policy summary`}</h2>
          </Grid>
        )}
        {showPolicyStartDate && (
          <div className={classes.autoAndPropertyCoveragesFormPolicyStartDateCalendarRow}>
            <div className={classes.autoAndPropertyCoveragesFormPolicyStartDateCalendarContainer}>
              <PolicyStartDateCalendar product={propertyOfferProduct} />
            </div>
          </div>
        )}
        {topOfPropertyCoveragesForm && (
          <Grid item xs={12} className={classes.addSpacing}>
            <ReviewHomePrefillAlert className={classes.addSpacingBelow} />
          </Grid>
        )}
        <CoveragesAccordion
          title={discountText}
          discountItems={propertyDiscountItems?.[propertyOfferProduct]}
          trackingName={`${productDisplayName}SpecificDiscounts`}
        />
        {coverageGroupKeys.map((key) => (
          <CoveragesAccordion
            title={metadata[propertyOfferProduct].titles[key]}
            coverageItems={propertyCoverages[key]}
            isDeductible={key === 'deductibles'}
            trackingName={`${productDisplayName}${metadata[propertyOfferProduct].trackingNames[key]}`}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {isCustomizeCoveragesEnabled ? renderCoveragesSummary() : renderCoverages()}
      {!coveragesMetadata.hideDisclaimers &&
        !areSomeSelectedProductsIndicative &&
        isProductHome(propertyOfferProduct) && (
          <GridItem topSpacing='lg' xs={12} className={classes.helpboxSection}>
            <HelpBox
              image={GraphicUIAgentAvatarImageUrl}
              data-testid='helpBoxIcon'
              content={
                <div>
                  <HomeRatingCriteriaDisclaimer {...props} stateCode={stateCode} />
                  <MessageDisclaimer {...props} stateCode={stateCode} />
                </div>
              }
            />
          </GridItem>
        )}
      {!coveragesMetadata.hideDisclaimers &&
        !env.static.isAgent &&
        !areSomeSelectedProductsIndicative &&
        isProductRenters(propertyOfferProduct) &&
        props.messages?.length > 0 && (
          <GridItem topSpacing='lg' xs={12} className={classes.helpboxSection}>
            <HelpBox
              image={GraphicUIAgentAvatarImageUrl}
              data-testid='helpBoxIcon'
              content={<MessageDisclaimer {...props} stateCode={stateCode} />}
            />
          </GridItem>
        )}
    </>
  );
});
