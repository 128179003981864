import { memo, useCallback } from 'react';

import type { AccordionProps } from '@mui/material';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { Accordion, GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { useIsMobile } from '@ecp/themes/base';

import { CoverageCard } from '../../CoverageCard';
import coveragesFormMetadata from '../../CoveragesForm/metadata';
import { hasCoverageItemError } from '../../util';
import { CededPolicyBanner } from '../AutoCoveragesForm/CededPolicyBanner';
import { useStyles } from '../CoveragesForm.styles';
import { DiscountsBlock } from '../DiscountsBlock';
import type { CoveragesAccordionProps, Props } from './types';

const CoveragesAccordion: React.FC<CoveragesAccordionProps> = memo((props) => {
  const { content, footer, product, showError, showPolicyCoverageBanner, title, trackingName } =
    props;
  const isDiscounts = 'discountItems' in props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const hideExpandIcon =
    !env.static.isAgent &&
    // VariantA - Zillow
    isMobile &&
    coveragesFormMetadata.isVariantACoverageStyle;
  const handleExpandCollapse = useCallback<NonNullable<AccordionProps['onChange']>>(
    (_event, expanded) => {
      trackClick({
        action: `show_hide_${trackingName?.toLowerCase()}`,
        label: expanded ? 'Show' : 'Hide',
      });
    },
    [trackingName],
  );

  return (
    <GridItem
      topSpacing={isDiscounts ? 'xs' : 'lg'}
      xs={12}
      className={cx(classes.addSpacing, classes.addSpacingVert)}
    >
      <MuiAccordion
        defaultExpanded={!env.static.isAgent || (!isDiscounts && env.static.isAgent)}
        className={classes.accordionContainer}
        onChange={handleExpandCollapse}
      >
        <AccordionSummary
          className={classes.accordionSummaryHeader}
          expandIcon={hideExpandIcon ? null : <div />}
        >
          <h3 data-testid={title} className={showError ? classes.accordionHeaderError : undefined}>
            {title}
          </h3>
        </AccordionSummary>
        {isDiscounts && (
          <AccordionDetails className={classes.accordionDetailsDiscountBkg}>
            <DiscountsBlock items={props.discountItems} footer={footer} product={product} />
          </AccordionDetails>
        )}
        {!isDiscounts && (
          <AccordionDetails
            className={cx(
              classes.accordionDetailsBkg,
              showError && classes.accordionDetailsBkgError,
              showPolicyCoverageBanner && classes.accordionDetailsBkgWithBanner,
            )}
          >
            {showPolicyCoverageBanner && <CededPolicyBanner />}
            <div
              className={showPolicyCoverageBanner ? classes.accordionDetailsWrapperBkg : undefined}
            >
              <Accordion>
                {props.coverageItems.map((item, key) => (
                  <CoverageCard key={key} isDeductible={props.isDeductible} {...item} />
                ))}
              </Accordion>
            </div>
          </AccordionDetails>
        )}
        {content && (
          <AccordionDetails className={classes.accordionDetailsDiscountBkg}>
            {content}
          </AccordionDetails>
        )}
      </MuiAccordion>
    </GridItem>
  );
});

const validateProps = (props: Props): props is CoveragesAccordionProps => {
  const items = 'discountItems' in props ? props.discountItems : props.coverageItems;

  return !!(items && items.length > 0) || !!props.footer; // in addition to checking if there are any discount of coverage items, the footer should be displayed if present
};

const CoveragesAccordionGuard: React.FC<Props> = memo((props) => {
  if (!validateProps(props)) return null;

  const showError = 'coverageItems' in props && props.coverageItems.some(hasCoverageItemError);

  return <CoveragesAccordion {...props} showError={showError} />;
});

export { CoveragesAccordionGuard as CoveragesAccordion };
