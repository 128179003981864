import { Typography } from '@mui/material';

import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { OCCUPANCY_TYPE } from '../../../../constants';
import metadata from './metadata';

const ID = 'OccupancyType';

export const OccupancyTypeQuestion: React.FC<QuestionProps> = (props) => {
  const occupancyType = useField(OCCUPANCY_TYPE);
  const {
    label = occupancyType.question?.title,
    trackingName = 'home_usage_selection',
    displayType,
    hideError,
  } = props;

  const error = hideError ? undefined : occupancyType.props.error;

  useAddFields({ occupancyType });

  useAddConditionValues({
    conditionalFields: [occupancyType],
    isRequiredOverride: () => true,
  });

  useInitValues({ [occupancyType.key]: 'PROPERTY.OCCUPANCY_TYPE.PRIMARY_HOME_OWNER_OCCUPIED' });

  if (!occupancyType.exists) return null;

  if (displayType === 'Select')
    return (
      <Select
        {...occupancyType.props}
        error={error}
        options={occupancyType.props.options}
        id={ID}
        trackingName={trackingName}
      />
    );

  return (
    <RadioGroupWithOptions
      {...occupancyType.props}
      error={error}
      id={ID}
      label={<Typography variant='body4'>{label}</Typography>}
      cardSize='small'
      trackingName={trackingName}
      options={metadata.options}
    />
  );
};
