import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { YEAR_BUILT } from '../../../../constants';

export const YearBuiltQuestion: React.FC<QuestionProps> = (props) => {
  const yearBuilt = useField(YEAR_BUILT);

  const {
    trackingName = 'year_built_question',
    groupLabel = yearBuilt.question?.title,
    dataTestId = 'yearBuilt',
    hideError = false,
  } = props;

  const error = hideError ? undefined : yearBuilt.props.error;

  useAddFields({ yearBuilt });

  if (!yearBuilt.exists) return null;

  return (
    <NumberFormat
      {...yearBuilt.props}
      error={error}
      groupLabel={groupLabel && <Typography variant='body4'>{groupLabel}</Typography>}
      format='####'
      id='YearBuilt'
      placeholder='YYYY'
      data-testid={dataTestId}
      trackingName={trackingName}
    />
  );
};
