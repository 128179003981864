import { IconUIMinusImageUrl, IconUIPlusImageUrl, makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoveragesForm' })((theme) => ({
  root: {
    ...theme.mixins.form,
    ...theme.mixins.withRightSidebar.contentContainer,
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0 !important',
    },
  },
  alertDiv: {
    marginBottom: 40,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '0px 15px 40px 15px',
    },
  },
  addSpacingVert: {
    marginTop: 40,
  },
  addSpacingBelow: {
    marginBottom: 40,
  },
  accordionContainer: {
    boxShadow: 'none',
  },
  accordionSummaryHeader: {
    '&.MuiAccordionSummary-root': {
      padding: 0,
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      minHeight: 30,
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content ': {
      marginTop: 0,
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '70%',
      },
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper  div ': {
      ...theme.typography.body1,
      color: theme.palette.text.link,
      marginTop: 3,
      display: 'inline-flex',
      '&:hover': {
        ...theme.typography.body1Underline,
        color: theme.palette.text.hover,
      },
    },
    '& .MuiAccordionSummary-expandIconWrapper.MuiIconButton-root ': {
      paddingTop: 0,
      paddingBottom: 10,
    },
    '& .MuiAccordionSummary-expandIconWrapper div:before ': {
      content: '"Expand"',
    },
    '& .MuiAccordionSummary-expandIconWrapper  div:after ': {
      content: '" "',
      mask: `url("${IconUIPlusImageUrl}")`,
      maskSize: 'cover',
      backgroundColor: theme.palette.text.link,
      display: 'inline-block',
      width: 24,
      height: 24,
      verticalAlign: 'middle',
      marginLeft: 2,
    },
    '& .MuiAccordionSummary-expandIconWrapper  div:hover:after ': {
      backgroundColor: theme.palette.text.hover,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded  div:before ': {
      content: '"Collapse"',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded  div:after ': {
      content: '" "',
      mask: `url("${IconUIMinusImageUrl}")`,
      maskSize: 'cover',
      backgroundColor: theme.palette.text.link,
      display: 'inline-block',
      width: 24,
      height: 24,
      verticalAlign: 'middle',
      marginLeft: 2,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded  div:hover:after ': {
      backgroundColor: theme.palette.text.hover,
    },
  },
  accordionHeaderError: {
    color: theme.palette.error.main,
  },
  discountsAccordionDetailsBkg: {
    background: theme.palette.grey[100],
    display: 'block',
    padding: 0,
  },
  accordionDetailsBkg: {
    background: theme.palette.grey[100],
    display: 'block',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 5px',
    },
  },
  accordionDetailsBkgWithBanner: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  accordionDetailsWrapperBkg: {
    display: 'block',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
  accordionDetailsBkgError: {
    background: theme.palette.error.light,
  },
  accordionDetailsDiscountBkg: {
    background: theme.palette.grey[100],
    padding: 20,
  },
  accordionSubHeader: {
    ...theme.typography.body3Bold,
    marginBottom: 11,
    marginTop: 4,
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  autoCoverageStartDate: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    paddingBottom: 10,
    marginBottom: 24,
    paddingTop: 38,
  },
  autoCoverageDiscountsMsg: theme.typography.body1,
  formContainer: {
    ...theme.mixins.withRightSidebar.formContainer,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: 30,
    },
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    '@media all and (-ms-high-contrast:none)': {
      display: 'block',
    },
  },
  next: theme.mixins.nextButton,
  buttonContainer: {
    textAlign: 'center',
    marginTop: 15,
  },
  coveragePageSubtitle: {
    fontFamily: theme.typography.fontFamily,
  },
  policyCoverageHeading: {
    ...theme.typography.h3,
    marginBottom: 15,
  },
  vehicleCoverageHeading: {
    ...theme.typography.h3,
    marginBottom: 20,
  },
  homePolicyCoverageHeading: {
    ...theme.typography.h3,
    marginBottom: 15,
  },
  disclaimerContent: {
    ...theme.typography.body1Italics,
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      margin: '30px 15px 0px',
    },
  },
  coveragePageTitle: {
    ...theme.typography.h2,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    paddingBottom: 10,
  },
  disclaimerDivider: {
    marginTop: 15,
    marginBottom: 15,
  },
  policyStartDateDetails: {
    display: 'none',
  },
  calenderIcon: {
    height: 20,
    width: 20,
    marginRight: 5,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  policyStartDateText: {
    fontWeight: 'bold',
  },
  changeDateLink: {
    lineHeight: '20px',
    height: 'auto',
    fontSize: 14,
  },
  checkoutCard: {
    paddingTop: 0,
  },
  sidebarContainerRoot: {
    ...theme.mixins.withRightSidebar.sidebarContainer,
    marginTop: -130,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  agentSidebarContainerRoot: {
    marginTop: 8,
  },
  dateInputField: {
    marginTop: 15,
    width: 'auto',
  },
  addSpacing: theme.mixins.addSpacing,
  productIcon: {
    marginRight: 18,
    width: 37,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  productHeading: {
    marginTop: 40,
    alignItems: 'center',
  },
  productHeadingWhenShowingPolicyStartDate: {
    marginBottom: 30,
    alignItems: 'center',
  },
  helpboxSection: {
    [theme.breakpoints.down('md')]: {
      margin: '20px 15px 0px',
    },
  },
  CoverageSummaryItemTitle: {
    ...theme.typography.body1Bold,
  },
  coverageSumLeftWrapper: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.grey[100],
  },
  coverageSumRight: {
    ...theme.typography.body2,
  },
  coverageSumContent: {
    '& .MuiGrid-item:first-child': {
      margin: 0,
    },
  },
  topPolicyStartDateCalendarContainer: {
    marginTop: -7,
  },
  autoAndPropertyCoveragesFormPolicyStartDateCalendarRow: {
    padding: '0 8px',
  },
  autoAndPropertyCoveragesFormPolicyStartDateCalendarContainer: {
    padding: '0 8px',
  },
  bundlePolicyStartDateHeading: {
    width: '100%',
    marginBottom: 30,
  },
  bundlePolicyStartDateHeadingToolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
    marginLeft: 5,
  },
}));
