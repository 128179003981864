// NOTE: selectors in the different directories should concern themselves with their own state tree
// they should NOT import functions between each other, as that can introduce import cycles.
// the selectors in this file are for any that do need data from different trees, this file can freely
// import from any of the other directories, inquiry/selectors.ts and offers/selectors.ts for example.
// TODO: either move all other selectors that offend the above rule here, or change selectors to be
// one-per-file (at least for complicated ones).

import { castToBoolean } from '@ecp/utils/common';

import { CoveragePolicyLevelDiscountsMetadata } from '@ecp/features/sales/shared/metadata';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { getProductNameFromProduct, isLineOfBusinessBundle } from '@ecp/features/shared/product';
import type { OptionMetadata } from '@ecp/types';

import { getPrimaryInsuredStateCode } from './form';
import { getAnswer, getLineOfBusiness } from './inquiry';
import { getIsBundleForOfferProductsSelected, getOfferProductsSelectedByType } from './offers';

// Top of the funnel selection needs to be bundle and bottom of the funnel - monoline for multi product discount to be excluded
const shouldApplyMultiProductDiscount = (state: RootStore): ((key: string) => boolean) => {
  const isLobBundle = isLineOfBusinessBundle(getLineOfBusiness(state));
  const isBundleForOfferProductsSelected = getIsBundleForOfferProductsSelected(state);

  return (key: string): boolean =>
    !multiProductDiscountKeys.includes(key) || !isLobBundle || isBundleForOfferProductsSelected;
};

const makePolicyLevelDiscounts = (
  state: RootStore,
  offerProduct: Product,
  stateCode: string,
): OptionMetadata[] => {
  const metadata = CoveragePolicyLevelDiscountsMetadata[offerProduct];

  // Policy level discount answer keys for auto product have a format like amfam.auto.discount.policy.multiVehicle
  // Whereas for other products - homesite.home.discounts.packageDiscount
  const product = getProductNameFromProduct(offerProduct);
  const answerKeySubstring = product === 'auto' ? 'policy.' : '';
  const discountKeySubstring = product === 'auto' ? 'discount' : 'discounts';

  const discounts = Object.keys(metadata)
    .filter((key) =>
      castToBoolean(
        getAnswer(state, `${offerProduct}.${discountKeySubstring}.${answerKeySubstring}${key}`),
      ),
    )

    /**
     * TODO: SAPI v4 needs have the ability to send discounts for monoline and bundle separately.
     * SAPI v3 sends all discounts to the quote summary page and erroniously associates multipolicy discounts with the monoline tabs.
     * The multipolicy discounts should only be included with bundle offers.
     * */
    .filter(shouldApplyMultiProductDiscount(state))
    .map((key: keyof typeof metadata) => {
      const stateOption = metadata[key].stateOptions?.[stateCode];
      if (stateOption) {
        Object.entries(stateOption).forEach(([k, v]) => {
          // Inferred type of v is a union of all possible OptionMetadata values
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          metadata[key][k] = v as any;
        });
      }

      return metadata[key];
    });

  return discounts;
};

const multiProductDiscountKeys = ['multiProductDiscount', 'MultiPolicy', 'midvaleHomePolicy'];

export const getPolicyLevelDiscounts = (
  state: RootStore,
): Partial<Record<ProductName, OptionMetadata[]>> => {
  const { property, auto } = getOfferProductsSelectedByType(state);
  const stateCode = getPrimaryInsuredStateCode(state);
  const propertyProductName = property && getProductNameFromProduct(property);

  const policyLevelDiscounts = {
    ...(auto && { auto: makePolicyLevelDiscounts(state, auto, stateCode) }),
    ...(propertyProductName && {
      [propertyProductName]: makePolicyLevelDiscounts(state, property, stateCode),
    }),
  } as const;

  return policyLevelDiscounts;
};
