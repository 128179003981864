import type { FC } from 'react';

import { datadogLog } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';

import { Link } from '@ecp/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';

const logClick = (): void => {
  datadogLog({
    message: 'HomePrefillSummaryPageLink click',
    logType: 'info',
    context: {
      logOrigin:
        'libs/features/sales/shell/src/views/Coverages/CoveragesForm/ReviewHomePrefillAlert/HomePrefillSummaryPageLink.tsx',
      functionOrigin: 'HomePrefillSummaryPageLink',
    },
  });
};

export const HomePrefillSummaryPageLink: FC = (props) => {
  const navigateToHomePrefillSummary = useNavigateToPage(PagePath.HOME_PREFILL_SUMMARY, {
    replace: true,
    removeQuery: true,
  });

  const handleClick = useEvent(async () => {
    await navigateToHomePrefillSummary();
    logClick();
  });

  return (
    <Link
      component='button'
      onClick={handleClick}
      trackingLabel='HomePrefillAlert_Link'
      trackingName='HomePrefillAlert_Link'
    >
      Review & edit property details
    </Link>
  );
};
