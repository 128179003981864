import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { getCurrentFlows, getOfferProductsSelectedByType } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { isProductHome } from '@ecp/features/shared/product';

const useIsShortHomeFlow = (): boolean => {
  const flow = useSelector(getCurrentFlows);

  return flow.home === PrefillFlow.SHORT;
};

const useOfferStatus = (): {
  isBundleOffer: boolean;
  hasHomeOffer: boolean;
} => {
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const isBundleOffer = !!(autoOfferProduct && propertyOfferProduct);
  const hasHomeOffer = !!propertyOfferProduct;

  return {
    isBundleOffer,
    hasHomeOffer,
  };
};

export type ShowAlertAtLocations = {
  topOfCoverageForm: boolean;
  topOfPropertyCoveragesForm: boolean;
  ratingCriteriaPageAutoHomeFormHomeVariantBHeader: boolean;
  ratingCriteriaPageAutoHomeFormHomeOriginalHeader: boolean;
};

export const determineShowAlertAtLocations = ({
  isVariantB,
  isFlowShortHome,
  ratingCriteriaAutoHomeFormProduct,
  isBundleOffer,
  hasHomeOffer,
}: {
  isVariantB: boolean;
  isFlowShortHome: boolean;
  ratingCriteriaAutoHomeFormProduct?: Product;
  isBundleOffer: boolean;
  hasHomeOffer: boolean;
}): ShowAlertAtLocations => {
  let topOfCoverageForm = false;
  let topOfPropertyCoveragesForm = false;

  if (!isVariantB) {
    return {
      topOfCoverageForm: false,
      topOfPropertyCoveragesForm: false,
      ratingCriteriaPageAutoHomeFormHomeVariantBHeader: false,
      ratingCriteriaPageAutoHomeFormHomeOriginalHeader: isProductHome(
        ratingCriteriaAutoHomeFormProduct,
      ),
    };
  }

  if (!isFlowShortHome) {
    return {
      topOfCoverageForm: false,
      topOfPropertyCoveragesForm: false,
      ratingCriteriaPageAutoHomeFormHomeVariantBHeader: false,
      ratingCriteriaPageAutoHomeFormHomeOriginalHeader: isProductHome(
        ratingCriteriaAutoHomeFormProduct,
      ),
    };
  }

  if (isBundleOffer) {
    topOfCoverageForm = false;
    topOfPropertyCoveragesForm = true;
  } else if (hasHomeOffer) {
    topOfCoverageForm = true;
    topOfPropertyCoveragesForm = false;
  }

  const ratingCriteriaPageAutoHomeFormHomeVariantBHeader =
    isVariantB && isProductHome(ratingCriteriaAutoHomeFormProduct);
  const ratingCriteriaPageAutoHomeFormHomeOriginalHeader =
    !isVariantB && isProductHome(ratingCriteriaAutoHomeFormProduct);

  return {
    topOfCoverageForm,
    topOfPropertyCoveragesForm,
    ratingCriteriaPageAutoHomeFormHomeVariantBHeader,
    ratingCriteriaPageAutoHomeFormHomeOriginalHeader,
  };
};

export const useShouldShowReviewHomePrefillAlert = (
  ratingCriteriaAutoHomeFormProduct?: Product,
): ShowAlertAtLocations => {
  const isFlowShortHome = useIsShortHomeFlow();
  const { isBundleOffer, hasHomeOffer } = useOfferStatus();

  return determineShowAlertAtLocations({
    isVariantB: flagValues[FeatureFlags.HOME_PREFILL_SUMMARY_PAGE_ABC_TEST] === 'VARIANT_B',
    isFlowShortHome,
    ratingCriteriaAutoHomeFormProduct,
    isBundleOffer,
    hasHomeOffer,
  });
};
