import { useCallback, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';

import { castToBoolean, emptyObject, isKeyOf } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, PhoneLink } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddFields } from '@ecp/features/sales/form';
import { AdditionalWildfireMitigationForm } from '@ecp/features/sales/quotes/property/home';
import { CheckboxGroup } from '@ecp/features/sales/shared/components';
import {
  DERIVED_DISCOUNT_AUTO,
  DERIVED_DISCOUNT_HOME,
  DISCOUNT_PROPERTY_WILDFIRE_MITIGATION,
  EXISTING_AUTO_PRODUCT,
  EXISTING_HOME_PRODUCT,
} from '@ecp/features/sales/shared/constants';
import { LengthOfResidenceQuestion } from '@ecp/features/sales/shared/questions';
import {
  getAutoProduct,
  getHomeProduct,
  getLineOfBusiness,
} from '@ecp/features/sales/shared/store';
import {
  getField,
  getPrimaryInsuredAddressInfo,
  updateAnswers,
  useField,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import type { CardOption, Fields } from '@ecp/types';

import { useStyles } from '../DiscountsForm.styles';
import * as metadata from './metadata';

interface Props {
  discountChecks?: { [discount: string]: boolean };
}

const useGetDiscountsFields = (options: CardOption[]): Fields => {
  const dispatch = useDispatch();

  const selectors = options.map((option) => (state: RootStore) => {
    const f = getField(state, { key: option.value, questionKey: option.value, dispatch });

    return f;
  });

  const fieldsSelector = createSelector(selectors, (...fields) => {
    return fields.reduce((acc, field) => {
      acc[field.key] = field;

      return acc;
    }, {} as Fields);
  });

  return useSelector(fieldsSelector);
};

export const DiscountsFormBodyHome: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { discountChecks = emptyObject as unknown as { [discount: string]: boolean } } = props;
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const discountOptions = metadata.discountOptions[lineOfBusiness];
  const dispatch = useDispatch();
  const autoProduct = useSelector(getAutoProduct);
  const homeProduct = useSelector(getHomeProduct);

  const { line1, line2, city, state, zipcode } = useSelector(getPrimaryInsuredAddressInfo);
  const wildfireMitigationDiscount = useField('discount.property.wildfireMitigation');
  const caWildfireMandateFlag = flagValues[FeatureFlags.CA_WILDFIRE_MANDATE];
  const showWildfireMandateConsumer =
    wildfireMitigationDiscount.value && !env.static.isAgent && caWildfireMandateFlag;
  const showWildfireMandateAgent =
    wildfireMitigationDiscount.value && env.static.isAgent && caWildfireMandateFlag;
  const fields = useGetDiscountsFields(discountOptions);
  useAddFields({ ...fields });
  const existingAutoProduct = useField(EXISTING_AUTO_PRODUCT);
  const existingHomeProduct = useField(EXISTING_HOME_PRODUCT);
  const sapiDiscountOptions = useMemo(() => {
    const fieldKeys = Object.keys(fields);

    return discountOptions
      .filter((discountOption) => {
        // Check if the discount is applicable based on the field keys and derived discounts
        const isApplicable =
          fieldKeys.includes(discountOption.value) ||
          discountOption.value === DERIVED_DISCOUNT_AUTO ||
          discountOption.value === DERIVED_DISCOUNT_HOME;

        // Return feature flag value for Wildfire Mitigation only when state is 'CA'
        if (discountOption.value === DISCOUNT_PROPERTY_WILDFIRE_MITIGATION) {
          if (state === 'CA' && wildfireMitigationDiscount.exists) {
            return caWildfireMandateFlag;
          } else {
            return false;
          }
        }
        // EDSP-14502 for 01-16-2025 release. This will be removed if MI changed to amfam.auto
        if (discountOption.value === DERIVED_DISCOUNT_AUTO && state === 'MI') {
          return false;
        }

        return isApplicable;
      })
      .map((discountOption) => {
        const returnDiscountOption: CardOption<string, string> = {
          ...discountOption,
          ...discountOption.stateOptions?.[state],
        };

        return returnDiscountOption;
      });
  }, [fields, wildfireMitigationDiscount, caWildfireMandateFlag, discountOptions, state]);
  const values = useMemo(() => {
    return sapiDiscountOptions
      .filter((option) => {
        const key = option.value;
        if (!isKeyOf(key, discountChecks)) {
          const field = fields[key];

          return !!(field && castToBoolean(field.value));
        }

        return discountChecks[key];
      })
      .map((option) => option.value);
  }, [sapiDiscountOptions, discountChecks, fields]);

  // HSECP-825 This would only work with SAPI v3 and it's incorrect approach actually
  // In SAPI v4 scenario DAL uses static.derived.discount.auto/home/etc. answer directly
  // TODO - Remove usage of existingHomeProduct, existingAutoProduct usages in ECP UI once we retire v3
  const actionOnComplete = useCallback(
    async (value: string[], valueClicked?: string, newChecked?: boolean): Promise<void> => {
      if (valueClicked) {
        const answers = { [valueClicked]: newChecked };
        if (valueClicked === DERIVED_DISCOUNT_AUTO) {
          if (newChecked) {
            existingAutoProduct.validateUpdateAndPatch(autoProduct);
          } else {
            existingAutoProduct.validateUpdateAndPatch(null);
          }
        }
        if (valueClicked === DERIVED_DISCOUNT_HOME) {
          if (newChecked) {
            existingHomeProduct.validateUpdateAndPatch(homeProduct);
          } else {
            existingHomeProduct.validateUpdateAndPatch(null);
          }
        }
        await dispatch(updateAnswers({ answers }));
      }
    },
    [autoProduct, dispatch, existingAutoProduct, existingHomeProduct, homeProduct],
  );

  return (
    <>
      <CheckboxGroup
        className={classes.discountsCheckBoxGroup}
        name='home_discounts'
        cardSize='medium'
        options={sapiDiscountOptions}
        values={values}
        actionOnComplete={actionOnComplete}
        trackingName='home_discounts'
      />

      {showWildfireMandateConsumer && (
        <div className={classes.wildfireMitigationSection}>
          To continue with the <b>Wildfire mitigation</b> discount selected, an agent will need to
          ask you some additional questions about your property. Please call{' '}
          <PhoneLink number={partner.shared.salesPhoneNumber} withUnderlinedLinkStyle />.
        </div>
      )}

      {showWildfireMandateAgent && <AdditionalWildfireMitigationForm />}

      <GridItem topSpacing='lg' xs={12}>
        <LengthOfResidenceQuestion
          forLineOfBusiness={LineOfBusiness.HOME}
          groupLabelHome={`How many years have you lived at ${line1} ${line2} ${city}, ${state} ${zipcode}?`}
        />
      </GridItem>
    </>
  );
};
