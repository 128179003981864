import { useCallback } from 'react';

import type { AccordionProps } from '@mui/material';
import { AccordionDetails, AccordionSummary, Grid, Accordion as MuiAccordion } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { Accordion } from '@ecp/components';
import { env } from '@ecp/env';
import type { VehicleCoverages } from '@ecp/features/sales/shared/types';

import { CoverageCard } from '../../../CoverageCard';
import { hasCoverageItemError } from '../../../util';
import { useStyles } from '../../CoveragesForm.styles';
import * as metadata from './metadata';

interface Props {
  vehicles: VehicleCoverages[];
}

export const VehicleCoveragesForm: React.FC<Props> = (props) => {
  const { vehicles } = props;
  const { classes, cx } = useStyles();

  const handleChange = useCallback<NonNullable<AccordionProps['onChange']>>((event, expanded) => {
    trackClick({
      action: 'show_hide_vehicle_level_coverages',
      label: expanded ? 'Show' : 'Hide',
    });
  }, []);

  const vehiclesElements = vehicles.map((vehicle, index) => {
    const { coverages, deductibles, description } = vehicle;
    const showDeductiblesError = deductibles.some(hasCoverageItemError);
    const showCoveragesError = coverages.some(hasCoverageItemError);
    const hideExpandIcon = !env.static.isAgent;

    return (
      <Grid
        key={`${description} ${index}`}
        item
        xs={12}
        className={cx(classes.addSpacing, classes.addSpacingVert)}
      >
        <MuiAccordion
          className={classes.accordionContainer}
          defaultExpanded
          onChange={handleChange}
        >
          <AccordionSummary
            className={classes.accordionSummaryHeader}
            expandIcon={hideExpandIcon ? null : <div />}
          >
            <h3
              className={
                showDeductiblesError || showCoveragesError
                  ? classes.accordionHeaderError
                  : undefined
              }
            >
              {metadata.vehicleCoveragesFormMetadata.title}
              {description}
            </h3>
          </AccordionSummary>
          <AccordionDetails
            className={cx(
              classes.accordionDetailsBkg,
              (showDeductiblesError || showCoveragesError) && classes.accordionDetailsBkgError,
            )}
          >
            <Grid item xs={12}>
              <Accordion>
                <p className={classes.accordionSubHeader}>
                  {metadata.vehicleCoveragesFormMetadata.hideVehicleElementsNotAvailable
                    ? deductibles.length
                      ? 'Deductibles'
                      : 'Deductibles: Not Available'
                    : 'Deductibles'}
                </p>
                {deductibles.length ? (
                  deductibles.map((item, key) => <CoverageCard key={key} {...item} isDeductible />)
                ) : (
                  <Grid item xs={12} />
                )}
                <p className={classes.accordionSubHeader}>
                  {metadata.vehicleCoveragesFormMetadata.hideVehicleElementsNotAvailable
                    ? coverages.length
                      ? 'Coverages and benefits'
                      : ''
                    : 'Coverages and benefits'}
                </p>
                {coverages.length ? (
                  coverages.map((item, key) => <CoverageCard key={key} {...item} />)
                ) : (
                  <Grid item xs={12} />
                )}
              </Accordion>
            </Grid>
          </AccordionDetails>
        </MuiAccordion>
      </Grid>
    );
  });

  return <> {vehiclesElements} </>;
};
