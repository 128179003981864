import { memo } from 'react';

import { env } from '@ecp/env';
import { useIsTablet } from '@ecp/themes/base';

import { useStyles } from './CoveragesPageTitle.styles';
import { useTitle } from './util';

export const CoveragesPageTitle: React.FC = memo(() => {
  const { classes } = useStyles();
  const title = useTitle();
  const isTablet = useIsTablet();

  if (isTablet && env.static.isAgent) {
    return null;
  }

  return <h1 className={env.static.isAgent ? undefined : classes.title}>{title}</h1>;
});
