import { env } from '@ecp/env';
import {
  getIsBundleForOfferProductsSelected,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useIsMobile } from '@ecp/themes/base';

/**
 * note:
 * on desktop, we show the policy start date on the coverages form
 * on mobile, we show the policy start date on each property/auto form
 */

export const useLiftoffDesktopTopStyles = (): {
  showPolicyStartDateBundleHeading: boolean;
  showAutoPolicyStartDate: boolean;
  showPropertyPolicyStartDate: boolean;
  isLiftoffDesktop: boolean;
} => {
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const isMobile = useIsMobile();

  const isLiftoffDesktop = !env.static.isAgent && !isMobile;
  const showPolicyStartDateBundleHeading = isLiftoffDesktop && isBundle;
  const showAutoPolicyStartDate = !!(isLiftoffDesktop && autoOfferProduct);
  const showPropertyPolicyStartDate = !!(isLiftoffDesktop && propertyOfferProduct);

  return {
    isLiftoffDesktop,
    showAutoPolicyStartDate,
    showPropertyPolicyStartDate,
    showPolicyStartDateBundleHeading,
  };
};

export const useShowLiftoffMobilePolicyStartDateOnAutoOrPropertyForms = (): {
  showPolicyStartDate: boolean;
} => {
  const isMobile = useIsMobile();
  const showPolicyStartDate = isMobile && !env.static.isAgent;

  return { showPolicyStartDate };
};

export const useMoonshotAgentPolicyStartDateForm = (): boolean => {
  const { isLiftoffDesktop } = useLiftoffDesktopTopStyles();
  const { showPolicyStartDate: showPolicyStartDateOnAutoOrPropertyForms } =
    useShowLiftoffMobilePolicyStartDateOnAutoOrPropertyForms();

  return !(isLiftoffDesktop || showPolicyStartDateOnAutoOrPropertyForms);
};
