import { useCallback, useState } from 'react';

import { isTruthy } from '@ecp/utils/common';
import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import { AddressAutoCompleteQuestion } from '@ecp/features/sales/shared/questions';
import {
  getPrimaryInsuredAddressInfo,
  getSelectedPropertyProduct,
  getZipLookupBypassed,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { useStyles } from './AddressQuestions.styles';

interface Props {
  isDisabled?: boolean;
}

export const AddressQuestions: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const [geoAddressSuggestions, setGeoAddressSuggestions] = useState<GeoAddress[]>([]);
  const { line1, line2, city, state, zipcode } = useSelector(getPrimaryInsuredAddressInfo);
  const zipcodeBypassed = useSelector(getZipLookupBypassed);
  const [collapsed, setCollapsed] = useState(zipcodeBypassed);

  const changeAddress = useCallback(() => {
    setCollapsed(false);
  }, []);

  const selectedPropertyProduct = useSelector(getSelectedPropertyProduct);
  const productName = selectedPropertyProduct && getProductNameFromProduct(selectedPropertyProduct);

  return (
    <div className={classes.root}>
      <p className={classes.groupLabel}>Address where you need {productName} insurance</p>
      {collapsed ? (
        <GridItem topSpacing='xs' className={classes.content}>
          {[line1, line2].filter(isTruthy).join(', ')},
          <br />
          {[city, state, zipcode].join(', ')}{' '}
          <Button
            className={classes.expandButton}
            onClick={changeAddress}
            variant='iconTextMedium'
            trackingName='changeAddressLink'
            trackingLabel='changeAddress'
            analyticsElement='choice.landingPage.changeAddressLink'
          >
            Change address
          </Button>
        </GridItem>
      ) : (
        <AddressAutoCompleteQuestion
          setGeoAddressSuggestions={setGeoAddressSuggestions}
          geoAddressSuggestions={geoAddressSuggestions}
          isDisabled={props.isDisabled}
        />
      )}
    </div>
  );
};
