import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import { LIVING_AREA } from '../../../../constants';
import { useStyles } from './LivingAreaQuestion.styles';

export const LivingAreaQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const livingArea = useField(LIVING_AREA);

  const {
    trackingName = 'square_footage',
    groupLabel = livingArea.question?.title,
    hideError = false,
  } = props;

  const error = hideError ? undefined : livingArea.props.error;

  useAddFields({ livingArea });

  if (!livingArea.exists) return null;

  return (
    <NumberFormat
      {...livingArea.props}
      error={error}
      groupLabel={groupLabel && <Typography variant='body4'>{groupLabel}</Typography>}
      id={livingArea.props.name}
      format='####'
      trackingName={trackingName}
      classes={{ input: classes.numberInput }}
    />
  );
};
